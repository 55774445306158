.price-container {
    display: flex;
    margin-bottom: 5%;
}
.item {
    width: 100%;
}
.label {
    margin-top: 2%;
    margin-right: 5%;
}

.form-control {
    margin-right: 5%;
}

.apartment-option {
    display:flex;
    
}

.apartment-label {
    margin-right: 20%;
}
