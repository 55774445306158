/* Main container for the entire page, if not already defined */
.listing-container {
  display: flex;
  height: 100vh; /* Full viewport height */
}
.info-container {
  display: flex;
  flex-direction: column;
  align-items: left;
  width: 50%; /* Half of the container's width */
  max-height: 100vh; /* Full viewport height */
  overflow: auto; /* Enable scrolling if content is taller than the viewport */
  padding: 20px; /* Adjust as needed */
}
.listing-details {
  display: flex;
}
.alert {
  width: 25%;
  height:5%;
  line-height: 5%;
  text-align: center;
}
.image-gallery {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 50%; /* Half of the container's width */
  max-height: 100vh; /* Full viewport height */
  overflow: auto; /* Enable scrolling if content is taller than the viewport */
  padding: 20px; /* Adjust as needed */
}

.selected-image {
  width: calc(100% - 40px); /* Adjust width considering padding */
  margin-bottom: 20px; /* Space between the main image and thumbnails */
  overflow: hidden;
}

.selected-image img {
  width: 700px;
  height: 432px;
  object-fit: cover;
  object-position: center;
  border-radius: 20px; /* Optional: for rounded corners */
}

.thumbnail-container {
  display: flex;
  flex-wrap: nowrap; /* Prevent thumbnails from wrapping */
  overflow-x: auto; /* Enable horizontal scrolling for thumbnails */
  gap: 10px; /* Space between thumbnails */
}

.thumbnail {
  flex: 0 0 auto; /* Do not grow or shrink, but allow basis to be auto */
  width: 100px; /* Adjust thumbnail size as necessary */
  height: 100px;
  cursor: pointer;
  border: 1px solid #ccc; /* Border color for thumbnails */
  border-radius: 4px; /* Optional: for rounded corners */
  display: flex;
  align-items: center;
  justify-content: center;
}

.thumbnail img {
  width: 100%;
  height: auto;
}

.thumbnail.active {
  border-color: #000; /* Active thumbnail border color */
}

.no-image {
  width: 100%;
  height: 100%;
  background-color: #f0f0f0; /* Placeholder color */
  display: flex;
  align-items: center;
  justify-content: center;
  color: #666; /* Text color for the placeholder */
  font-style: italic;
}

.show-all {
  padding: 10px 20px;
  background-color: #007bff; /* Button background color */
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 16px; /* Adjust button text size as necessary */
  align-self: center; /* Center button in the gallery space */
}

.show-all:hover {
  background-color: #0056b3; /* Button hover state background color */
}

/* Apply responsive adjustments as needed */
@media (max-width: 768px) {
  .main-container {
    flex-direction: column;
  }

  .image-gallery {
    width: 100%;
    height: 50vh; /* Take up half of the viewport height */
  }
  
  .thumbnail-container {
    flex-wrap: wrap; /* Allow thumbnails to wrap on small screens */
  }
  
  .thumbnail {
    width: 80px;
    height: 80px;
  }
  
  .selected-image img {
    width: 100%;
  }
}

